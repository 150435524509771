import * as React from "react"

import defaults from "../../../components/dafaults"

// components
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Search from "../../../components/Search/index"

// styles
import "../../../styles/css/styles.css"
import Loading from "../../../components/shared/Loading"

const decodeSlug = (slug) => {
    let newSlug = decodeURI(slug).replaceAll("+", " ")
    newSlug = newSlug.replaceAll("%2F", "/")
    return newSlug
}

const ListSearch = ({ location }) => {

    const [ listResults, setListResult ] = React.useState(null)
    const [ size, setSize ] = React.useState(null)
    // your document or window manipulation
    let url_string = location.search.split('&');
    let url = {}

    url_string.map(item => {
        let compare = item.split('=');
        url[compare[0].replaceAll('?','')] = compare[1]    
    })
    
    let CodCategoria = decodeSlug(url["CodCategoria"]);
    let CodMontadora = decodeSlug(url["CodMontadora"]);
    let CodModelo = decodeSlug(url["CodModelo"]);
    let CodDescModelo = decodeSlug(url["CodDescModelo"]);
    let CodMotor = decodeSlug(url["CodMotor"]);
    let CodAno = decodeSlug(url["CodAno"]);
    let Tipo = url["tipo"];

    React.useLayoutEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = {}
        
        if(CodCategoria != "undefined" && CodCategoria != "Selecione")
            raw["CodCategoria"] = CodCategoria  

        if(CodModelo != "undefined" && CodModelo != "Selecione")
            raw["CodModelo"] = CodModelo
   
        if(CodModelo != "undefined" && CodDescModelo != "Selecione")
            raw["CodDescModelo"] = CodDescModelo

        if(CodMotor != "undefined" && CodMotor != "Selecione")
            raw["CodMotor"] = CodMotor

        if(CodMotor != "undefined" && CodAno != "Selecione")
            raw["CodAno"] = CodAno

        if(CodMotor != "undefined" && Tipo != "Selecione")
            raw["Tipo"] = Tipo

        if(CodMotor != "undefined")
            raw[CodMontadora] = CodMontadora
        
        console.log("🚀 ~ file: index.jsx ~ line 45 ~ React.useLayoutEffect ~ raw", raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow'
        };

        fetch(`${defaults.api.base}advanced`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(listResults == null)
                    setListResult(result.Obj.Result)
                console.log("🚀 ~ file: index.jsx ~ line 53 ~ React.useLayoutEffect ~ result", result)
            })
            .catch(error => console.log('error', error));
    })

    React.useEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
    })

    const correctNames = {
        JsonApl: {
            title: "Veículos",
            columns: [
                "Linha",
                "Categoria",
                "Modelo",
                "Montadora",
                "Descrição do Modelo",
                "Motor",
                "Ano"
            ],
            values: [
                "Linha",
                "Categoria",
                "Modelo",
                "Montadora",
                "DescModelo",
                "Motor",
                "Ano"
            ]
        },
        JsonConv: {
            title: "Conversões",
            columns: [
                "Código Wega",
                "Código Concorrente",
                "Marca"
            ],
            values: [
                "CodigoComercialWega",
                "CodigoConcorrente",
                "Marca"
            ]
        },
        JsonDT: {
            title: "Produtos",
            columns: [
                "Código Wega",
                "Tipo de Filtro",
                "Comprimento",
                "Largura",
                "Altura",
                "Diâmetro Externo",
                "Diâmetro Interno"
            ],
            values: [
                "CodigoComercialWega",
                "DescTec",
                "Comprimento",
                "Largura",
                "Altura",
                "DiametroExt",
                "DiametroInt"
            ]
        }
    }
    
    

    return(
        <>
            <Header />
            <div className="searchList">
                <div className="container">
                    <h1>RESULTADO DE BUSCA</h1>
                    { listResults && listResults.length > 0 && <h2>{ correctNames["JsonApl"].title }</h2> }
                    <div className="list">
                        {

                            size > 991 &&
                            <div className="thead">
                                {
                                    correctNames["JsonApl"].columns.map(column => (
                                        <div>
                                            { column }
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    {
                        listResults ?
                            listResults.map(item => {
                                    
                                    return <>
                                            <div className="list">
                                    
                                                <div className="tbody">
                                                    {
                                                        size < 991 &&
                                                        <ul className="thead">
                                                        {
                                                            correctNames["JsonApl"].columns.map(column => (
                                                                <div>
                                                                    { column }
                                                                </div>
                                                            ))
                                                        }
                                                        </ul>
                                                    }
                                                    <ul className="content">
                                                    {
                                                        correctNames["JsonApl"].values.map(value => {
                                                            console.log("🚀 ~ file: index.jsx:190 ~ Object.keys ~ value", )
                                                            return(
                                                                value == "Modelo" ?
                                                                    <a href={`/busca/veiculo?CodModelo=${item["CodModelo"]}&ano=${item["Ano"]}&motor=${item['Motor']}&linha=${item['Linha']}&categoria=${item['Categoria']}&descmodelo=${item['DescModelo']}`}>
                                                                        { item[value] }
                                                                    </a>
                                                                :
                                                                    <div>
                                                                            { item[value] }
                                                                    </div>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                })
                            	
                        : 
                            <Loading />
                            // <div className="notFound">Nenhum resultado encontrado</div> 
                    }
                </div>
            </div>
            <Search internal={true} />
            <Footer />
        </>

    )
}

export default ListSearch